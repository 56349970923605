<template>
  <v-progress-circular class="loading" indeterminate color="black" :size="size" />
</template>

<script>
export default {
  name: 'Loading',
  props: {
    size: {
      type: Number,
      default: 32,
    },
  },
};
</script>
