<template>
  <v-dialog
    @click:outside="$emit('cancel')"
    :value="visible"
    content-class="v-dialog--overlay"
    width="500"
    persistent
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>

      <v-card-text>
        {{ content }}
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="$emit('confirm')"
          :loading="isLoading"
          :disabled="isLoading"
          >{{ $t('globals.yes') }}
        </v-btn>
        <v-btn color="secondary" text @click="$emit('cancel')" :disabled="isLoading"
          >{{ $t('globals.no') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DialogConfirmation',
  props: {
    title: String,
    content: String,
    visible: Boolean,
    isLoading: Boolean,
  },
};
</script>

<style scoped></style>
