<template>
  <div class="d-flex flex-column fill-height justify-space-between">
    <div v-if="eventIsLoaded">
      <div v-if="sortedAgenda.length">
        <div v-for="(scheduledItem, index) in sortedAgenda" :key="index">
          <conference-detail
            :activity="scheduledItem.conference"
            :event="event"
            :is-sending="isSending"
            :theme="getThemeColor(index)"
            :now="now"
            :is-admin="userIsAdmin"
            :is-on-user-agenda="true"
            @remove-from-agenda="removeConferenceFromAgenda"
            @participate="participate"
            v-if="scheduledItem.conference"
          />
          <discussion-group-detail
            :discussion-group="scheduledItem.discussionGroup"
            :event="event"
            :theme="getThemeColor(index)"
            :now="now"
            :is-admin="userIsAdmin"
            :is-on-user-agenda="true"
            @remove-from-agenda="removeDiscussionGroupFromAgenda"
            @enter-discussion-group="goToDiscussionGroup"
            v-if="scheduledItem.discussionGroup"
          />
          <meeting-agenda
            :appointment="scheduledItem.userEventAppointment"
            :theme="getThemeColor(index)"
            :now="now"
            :app-event="event"
            @remove-from-agenda="removeAppointmentFromAgenda"
            v-if="scheduledItem.userEventAppointment"
          />
        </div>
      </div>
      <p v-else class="d-flex justify-center pt-8">{{ $t('schedule.noActivity') }}</p>
    </div>

    <loading v-else />

    <sponsors :partners="getPartners()" showPlatinium includeOther />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import LayoutUtil from '@/helpers/layout/layout.helper';
import {
  GET_AGENDA,
  AGENDA_MODULE,
  REMOVE_CONFERENCE_FROM_AGENDA,
  REMOVE_DISCUSSION_GROUP_FROM_AGENDA,
  REMOVE_APPOINTMENT_FROM_AGENDA,
} from '@/stores/umanize-app/actions/agenda/agenda.actions';
import ConferenceDetail from '@/components/conference-detail/ConferenceDetail.vue';
import Sponsors from '@/components/sponsors/Sponsors.vue';
import Loading from '@/components/loading/Loading.vue';
import DiscussionGroupDetail from '@/components/discussion-group-detail/DiscussionGroupDetail.vue';
import { navigateToDiscussionGroup } from '@/navigation';
import { APP_EVENT_MODULE } from '@/stores/umanize-app/actions/event/app-event.actions';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';
import MeetingAgenda from '../components/meeting/MeetingAgenda.vue';

export default {
  name: 'Agenda',
  components: {
    MeetingAgenda,
    ConferenceDetail,
    Sponsors,
    Loading,
    DiscussionGroupDetail,
  },
  data: () => ({
    timer: null,
    now: new Date().toISOString(),
  }),
  computed: {
    ...mapGetters(AGENDA_MODULE, ['sortedAgenda', 'isSending']),
    ...mapGetters(APP_EVENT_MODULE, ['event', 'eventIsLoaded']),
    ...mapGetters(APP_USER_MODULE, ['loggedInUser']),
    userIsAdmin() {
      return this.loggedInUser.isAdmin;
    },
  },
  methods: {
    ...mapActions(AGENDA_MODULE, [
      GET_AGENDA,
      REMOVE_CONFERENCE_FROM_AGENDA,
      REMOVE_DISCUSSION_GROUP_FROM_AGENDA,
      REMOVE_APPOINTMENT_FROM_AGENDA,
    ]),
    getThemeColor(index) {
      return LayoutUtil.getThemeColor(index);
    },
    removeConferenceFromAgenda(conference) {
      this[REMOVE_CONFERENCE_FROM_AGENDA](conference);
    },
    removeDiscussionGroupFromAgenda(discussionGroup) {
      this[REMOVE_DISCUSSION_GROUP_FROM_AGENDA](discussionGroup);
    },
    removeAppointmentFromAgenda(appointment) {
      this[REMOVE_APPOINTMENT_FROM_AGENDA](appointment);
    },
    participate(conference) {
      this.$router.push({
        name: 'Conference',
        params: {
          eventId: conference.eventId,
          conferenceId: conference.id,
        },
      });
    },
    goToDiscussionGroup(discussionGroup) {
      navigateToDiscussionGroup(this.$router)({
        discussionGroupId: discussionGroup.id,
        eventId: discussionGroup.eventId,
      });
    },
    getPartners() {
      return this.event ? this.event.partners : [];
    },
  },
  mounted() {
    this[GET_AGENDA](this.$route.params.eventId);
  },
  created() {
    this.timer = setInterval(() => {
      this.now = new Date().toISOString();
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped></style>
