import { DateTime } from 'luxon';
import VueI18n from 'vue-i18n';
import DateUtil from '@/helpers/date/date.helper';
import type { User, PublicProfile } from '@/models';

export type UserEventAppointment = {
  id: string;
  eventId: string;
  slotUserId: string;
  slotUser: PublicProfile;
  bookerUserId: string;
  bookerUser: PublicProfile;
  userEventAvailabilityId: string;
  starting: string;
  ending: string;
  description: string;
  wherebyUrl?: string;
  wherebyHostUrl?: string;
};

const hasSameDate = (x: DateTime, y: DateTime): boolean =>
  x.hasSame(y, 'day') && x.hasSame(y, 'month') && x.hasSame(y, 'year');

export const formatAppointmentDate = (i18n: VueI18n) => (
  appointment: UserEventAppointment,
): string => {
  const starting = DateTime.fromISO(appointment.starting);
  const startingJS = starting.toJSDate();
  const ending = DateTime.fromISO(appointment.ending);
  const endingJS = ending.toJSDate();

  return hasSameDate(starting, ending)
    ? `${i18n.d(startingJS, 'longDate')} ${i18n.d(startingJS, 'shortTime')} - ${i18n.d(
        endingJS,
        'shortTime',
      )}`
    : `${i18n.d(startingJS, 'longDate')} ${i18n.d(startingJS, 'shortTime')} - ${i18n.d(
        endingJS,
        'longDate',
      )} ${i18n.d(endingJS, 'shortTime')}`;
};

export const bookingWith = (appointment: UserEventAppointment, me: User): PublicProfile => {
  if (!appointment || !me) {
    return null;
  }

  if (appointment.slotUserId === me.id) {
    return appointment.bookerUser;
  }

  return appointment.slotUser;
};

export const canAccessAppointment = (now: DateTime) => (
  appointment: UserEventAppointment,
): boolean => {
  const starting = DateTime.fromISO(appointment.starting).minus({ minutes: 15 }).toISO();

  return (
    DateUtil.isNowOrBefore(starting, now.toISO()) &&
    DateUtil.isAfter(appointment.ending, now.toISO())
  );
};
